<template>
  <article>
    <hgroup>
      <h1 style="margin-bottom: 62px;">개발 환경 준비하기</h1>
    </hgroup>
    <h2>Prerequisites</h2>
    <ul class="checklist">
      <li>
        damda 계정 (<a href="https://damda.lge.com">damda Console</a> &gt; 계정생성)
      </li>
      <li>라즈베리파이</li>
      <li>SD카드</li>
    </ul>
    <div style="margin-top: 26px;">
      라즈베리파이를 DAMDA 디바이스로 사용하기 위해 어떻게 셋팅을 해야하는지에 설명합니다.<br />
      이 과정은 라즈베리파이와 SD카드가 준비된 상태에서 진행합니다.
    </div>
    <h2><span class="chip">1단계</span>라즈베리파이 이미지 다운로드</h2>
    <div>
      <ol>
        <li>
          <a href="https://damda.lge.com">damda Console</a> &gt; 로그인 &gt; 가이드 &gt; 라즈베리파이 이미지 다운로드
        </li>
        <img
            src="/assets/img/getstarted1.png"
            alt=""
            style="margin-bottom: 30px;"
        />
        <li>
          damda-image.zip를 압축 해제하여 damda-{버전명}.img 이미지 파일 확인
        </li>
        <li>이미지 SD카드에 쓰기</li>
        <ul class="hyphen-ul">
          <li>
            <a href="https://www.raspberrypi.org/software/">https://www.raspberrypi.org/software/</a> &gt; 접속 &gt; Raspberry Pi Imager 다운로드
          </li>
          <li>
            Raspberry Pi Imager 실행 &gt; CHOOSE OS &gt; Use Custom &gt; CHOOSE STORAGE &gt; WRITE
          </li>
        </ul>
        <img
          src="/assets/img/getstarted1-1.png"
          alt=""
          style="margin-bottom: 35px;"
        />
        <li>SD카드를 라즈베리파이에 삽입 후 부팅</li>
        <ul>
          <li>
            SD카드 전체 용량을 활용하기 위해서는 라즈베리파이 이미지 부팅 후, 아래 명령 실행 및 재부팅 필요<br>
            실행하지 않을 경우, 이미지 설치 후 있는 1GB정도의 여유 공간만 활용 가능
          </li>
          <div>
            <pre><code class='hljs'>sudo raspi-config --expand-rootfs
sudo reboot</code></pre>
          </div>
          <li>
            초기 라즈베리파이 계정 정보
          </li>
            id: damda_user<br>
            pw: 1234qwer
          <li>
            라즈베리파이 환경설정 방법
          </li>
          라즈베리 파이에 DAMDA 디바이스 설치를 위해서는 네트워크를 연결해야 합니다.<br>
          보드와 함께 제공되는 랜선을 이용하여 연결 할 수 있습니다.<br>
          혹은 무선 설정 등 라즈베리파이의 환경설정(네트워크, 해상도 등)을 변경하고 싶으신 경우 아래 명령어를 사용하여 config에 들어가 설정 가능합니다.<br>
          <div>
            <pre><code class='hljs'>sudo raspi-config</code></pre>
          </div>
        </ul>
      </ol>
    </div>
    <hgroup>
      <h2><span class="chip">2단계</span>라즈베리파이에 damda설치</h2>
      <div class="note">
        라즈베리파이 부팅 후 라즈베리파이에서 아래 과정을 수행합니다.
      </div>
    </hgroup>
    1. 바탕화면에서 'damda Engine' 실행
    <img
      src="/assets/img/getstarted2.png"
      alt=""
      style="margin-top: 44px; margin-bottom: 61px;"
    />
    2. damda Engine 로그인 > 디바이스 메뉴에서 '디바이스 설치' 클릭
    <img
      src="/assets/img/getstarted3.png"
      alt=""
      style="margin-top: 41px; margin-bottom: 61px;"
    />
    <img
      src="/assets/img/getstarted3-1.png"
      alt=""
      style="margin-top: 40px; margin-bottom: 60px;"
    />
    3. 설치된 코어명 확인 후 '계정 연동' 클릭
    <img
      src="/assets/img/getstarted4.png"
      alt=""
      style="margin-top: 44px; margin-bottom: 60px;"
    />
    4. 계정 연동 완료 확인
    <img
      src="/assets/img/getstarted5.png"
      alt=""
      style="margin-top: 43px; margin-bottom: 60px;"
    />
    5. 개발 PC에서 <a href="http://damda.lge.com/documentation/index" target="_blank">damda Console</a> 접속 &gt; 디바이스 메뉴에서 damda 디바이스가 추가된 것을 확인
    <img
      src="/assets/img/getstarted6.png"
      alt=""
      style="margin-top: 43px; margin-bottom: 60px;"
    />

    <ul class="comment">
      <li>
        참고: 계정 연동을 하기 전에는 개발 PC의 위 화면에 damda 디바이스가 나타나지 않습니다.
      </li>
    </ul>
    <img
      src="/assets/img/getstarted7.png"
      alt=""
      style="margin-top: 43px; margin-bottom: 61px;"
    />
    <h2>Tips</h2>
    <h4>Python 기반 컴포넌트 개발</h4>
    <ul class="comment">
      <li>
        가상환경을 만들어 사용하고 싶은 경우, 컴포넌트 생성 시 실행스크립트에서 아래와 같이 {venv_path} 키워드를 사용하면 됩니다. 그러면 자동으로 해당 컴포넌트와 버전으로 구분되는 독립적인 가상환경을 제공합니다.
      </li>
      <div>
        <pre><code class='hljs'>python -m venv --without-pip {venv_path} && curl https://bootstrap.pypa.io/get-pip.py | {venv_path}/bin/python && {venv_path}/bin/pip install -r {root}/&lt;ZIP_FILE_NAME&gt;/requirements.txt && {venv_path}/bin/python {root}/&lt;ZIP_FILE_NAME&gt;/app.py</code></pre>
      </div>
    </ul>
    <br>
    <h4>Node 기반 컴포넌트 개발</h4>
    <ul class="comment">
      <li>
        추가로 필요한 패키지가 있는 경우, 라즈베리파이 환경에 맞는 node module 만들어 줍니다. node module을 포함하여 zip파일로 압축 한 후 배포합니다.
      </li>
      <ul class="comment">
        <li>
          주의: 실행스크립트에 npm install을 사용하면 첫 배포만 정상적으로 진행되고 다음 배포부터는 배포 오류가 발생합니다
        </li>
      </ul>
      <li>
        React, Vue등 Front End Webapp 배포시 build된 정적 파일을  배포하여 npx http-server로 실행하는 것을 권장합니다.
      </li>
    </ul>
    <br>
    <h4>컴포넌트 로그</h4>
    <ul class="comment">
      <li>
        컴포넌트 개발 시, 특정 경로에 파일로 컴포넌트 로그가 생성되도록 하면, DAMDA Engine의 로그 탭에서 로그를 확인하실 수 있습니다
      </li>
      <li>
        파일명은 &lt;컴포넌트명>.log 로 생성되어야 합니다
      </li>
      <ul class="comment">
        <li>
          로그 파일 경로 : /home/damda_user/damda-log/&lt;컴포넌트명&gt;.log
        </li>
      </ul>
    </ul>
  </article>
</template>

<script>
export default {};
</script>

<style></style>
